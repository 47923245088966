module controllers {
    export module master {

        interface IProductSKUPackagingCtrlScope extends ng.IScope {
        }

        interface IProductSKUPackagingParams extends ng.ui.IStateParamsService {
        }

        export class productSKUPackagingListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'skuService',
                '$state',
                '$stateParams',
                'productService',
                '$transitions',
            ];
            IsLoading: boolean = false;
            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.master.IProductSKUPackagingDisplay>;
            productMaster: interfaces.master.IProductMaster;
            searchAccordian: boolean = false;
            breadCrumbDesc: string;
            myHook: any;

            productSKUPackagingSearch: interfaces.master.IProductSKUPackagingSearch = {
            };

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;

            sortName: string;
            sortDirection: string;
            proId: number;

            filterNames: string[] = ["Id", "Code", "Description", "OrderUnitOfMeasure", "TypeOfPackage", "Quantity", "GrossWeight", "NettWeight", "VolumetricWeight", "Volume", "VolumeType",];

            filterList: interfaces.applicationcore.IKeyValue[] = [];

            dropdownsObject = {
                CompanyStatusList: Array<interfaces.applicationcore.IDropdownModel>(),
            };

            constructor(private $scope: IProductSKUPackagingCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private skuService: interfaces.master.ISKUService,
                private $state: ng.ui.IStateService,
                private $stateParams: IProductSKUPackagingParams,
                public productService: interfaces.master.IProductService,
                private $transitions: ng.ui.core.ITransition,
            ) {
                this.productSKUPackagingSearch = {
                };

                this.ctrlName = 'ProductSKUPackaging List Controller';

                this.loadProductSKUPackagings();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.Product.ProductSKUPackaging',
                    from: 'auth.MasterData.Product.ProductSKUPackaging.Update.**'
                }, () => {
                    this.loadProductSKUPackagings();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadProductSKUPackagings() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'productSKUPackagingList'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.productSKUPackagingSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            proId: this.$stateParams.proId
                        }

                        return this.skuService.getList().query(params, (result: Array<interfaces.master.IProductSKUPackagingDisplay>) => {
                            this.gvwProductSKUPackagings.data = result;
                            this.loadProduct(params.proId);
                            if (result[0]) {
                                this.gvwProductSKUPackagings.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwProductSKUPackagings.totalItems = 0;
                            }                           
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            loadProduct(Id: number) {

                return this.productService.getProduct().query({ Id: Id }, (data: interfaces.master.IProductMaster) => {

                    this.productMaster = data;
                    this.breadCrumbDesc = this.productMaster.Code + " - " + this.productMaster.ShortDescription                    
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;

            }
            public gvwProductSKUPackagings: uiGrid.IGridOptions | any = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: false,
                enableGridMenu: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: false,
                gridMenuShowHideColumns: false,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['EDIT', 'NUMRECS'],
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                            <div class="GridButton"> 
                                <button type="button" ng-click="grid.appScope.productSKUPackagingListCtrl.editProductSKUPackaging(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "Code",
                        displayName: "Code",
                    field: "Code",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "Description",
                    displayName: "Description",
                    field: "Description",
                    width: 200,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "OrderUnitOfMeasure",
                    displayName: "Order Unit Of Measure",
                    field: "OrderUnitOfMeasure",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "TypeOfPackage",
                    displayName: "Type Of Package",
                    field: "TypeOfPackage",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "Quantity",
                    displayName: "Quantity",
                    field: "Quantity",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "GrossWeight",
                    displayName: "Gross Weight (KG)",
                    field: "GrossWeight",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "NettWeight",
                    displayName: "Nett Weight (KG)",
                    field: "NettWeight",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "VolumetricWeight",
                    displayName: "Volumetric Weight",
                    field: "VolumetricWeight",
                    width: 200,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "Volume",
                    displayName: "Volume",
                    field: "Volume",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "VolumeType",
                    displayName: "Volume Type",
                    field: "VolumeType",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                }]
            };
           
            public editProductSKUPackaging(id: number) {
                this.$state.go("auth.MasterData.Product.ProductSKUPackaging.Update", { skuId: id });
            }
            
            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });
                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {
                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.master.IProductSKUPackagingDisplay = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }
                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }
        }

        angular.module("app").controller("productSKUPackagingListCtrl", controllers.master.productSKUPackagingListCtrl);
    }
}